<template>
    <el-row class="login-page">
        <el-col :span="12" class="bg"></el-col>
        <el-col :span="6" :offset="3" class="form">
            <div id="wechat">
            </div>
        </el-col>
    </el-row>
</template>

<script setup>
import { ref,onMounted } from 'vue'
import * as ww from '@wecom/jssdk'
import { userLoginService } from '@/api/user.js'
import { useTokenStore } from "@/store/token";
import { ElMessage } from 'element-plus';
import { useRouter } from "vue-router";
const router=useRouter()
const tokenStore=useTokenStore();

onMounted(() => {
  const wwLogin = ww.createWWLoginPanel({
    el: '#wechat', // 修改为模板中的ID
    params: {
      login_type: 'CorpApp',
      appid: 'ww8b29f05221264d4f',
      agentid: '1000012',
      redirect_uri: 'https://www.xiaolatech.cn',
      state: 'loginState',
      redirect_type: 'callback',
    },
    onCheckWeComLogin({ isWeComLogin }) {
      console.log(isWeComLogin)
    },
    onLoginSuccess({ code }) {
      login(code)
    },
    onLoginFail(err) {
      console.log(err)
    },
  })
  const div = document.getElementById("wechat")
})

const list = ref({
  "name":'',
  "areaIds":[]
})

const data = ref({
    "code":''
})

const userInfo = ref({
  "id":'',
  "realname":'',
  "wechatId":'',
  "username":'',
  "roleName":'',
  "roleCode":'',
  "token":''
})

const login =async(code)=>{
  data.value.code=code;
  const result=await userLoginService(data.value);
  list.value=result.data;

  if(result.code=="200"){
    ElMessage.success('登录成功');
    userInfo.value=result.data
    tokenStore.setToken(result.data.token);
    sessionStorage.setItem('username',userInfo.value.username);
    sessionStorage.setItem('realname',userInfo.value.realname);
    sessionStorage.setItem('roleCode',userInfo.value.roleCode);
    router.push('/home')
  }else{
    alert("用户核对不正确！请联系管理员")
  }
}
</script>

<style lang="scss" scoped>
.login-page {
    height: 100vh;
    background-color: #fff;
    .bg {
        background: url('@/assets/logo.png') no-repeat 60% center / 240px auto,
            url('@/assets/login_bg.jpg') no-repeat center / cover;
        border-radius: 0 20px 20px 0;
    }

    .form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        user-select: none;

        .title{
            margin: 0 auto;
        }

        .button{
            width: 100%;
        }

        .flex{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>