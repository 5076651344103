<template>
    <el-card class="page-container">
        <template #header>
            <div class="header">
                <span>订单登记</span>
            </div>
        </template>
        <!-- 搜索表单 -->
        <el-form inline id="topForm">
            <div id="search" :model="orderQueryDTO">
                <el-form-item label="订单号：">
                    <el-input v-model="orderQueryDTO.orderId" class="inputBar" @blur="orderList()" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="商家名称：">
                    <el-input v-model="orderQueryDTO.shopName" class="inputBar" @blur="orderList()" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="订单状态：">
                    <el-select v-model="orderQueryDTO.orderStatus" placeholder="请选择" style="width: 180px;" @blur="orderList()" clearable>
                        <el-option v-for="item in orderStatus" :key="item.orderStatus" :label="item.orderStatusName" :value="item.orderStatus" />
                    </el-select>
                </el-form-item>
                <el-form-item label="登记状态：">
                    <el-select v-model="orderQueryDTO.checkStatus" placeholder="请选择" style="width: 100px;" @blur="orderList()">
                        <el-option label="未跟进" value="0"></el-option>
                        <el-option label="已跟进" value="1"></el-option>
                        <el-option label="登记超时" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="送达时间：">
                    <el-date-picker
                        v-model="orderQueryDTO.orderPreEndDeliveryTime"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @blur="orderList()">
                    </el-date-picker>
                </el-form-item>
            </div>
            <div>
                <el-button type="success" 
                @click="profitListShow(orderQueryDTO)"
                plain
                v-loading.fullscreen.lock="fullscreenLoading"
                >毛利总计</el-button>
                <el-button type="success" @click="downloadExcel()" plain>导出账单</el-button>
                <el-button type="primary" class="button" @click="orderList()">搜索</el-button>
                <el-button class="button" @click="reset()">重置</el-button>
            </div>
        </el-form>
            <el-table :data="orderInfo" border style="width: 100%; height: 420px;" stripe
                    :header-cell-style="{backgroundColor: 'rgb(239 239 239)',height: '10%'}"
                    :cell-style="{fontSize: 'xx-small'}"
                    v-loading="loading"
                >
                <el-table-column label="订单号" prop="orderId" fixed width="160"></el-table-column>
                <el-table-column label="商家插件名称" prop="shopName" fixed width="120" />
                <el-table-column label="商家名称" prop="shopRealName" fixed width="100" />
                <el-table-column label="订单状态" prop="orderStatusName" fixed width="90"></el-table-column>
                <el-table-column label="登记状态" fixed width="90">
                    <template #default="scope">
                        <div v-if="scope.row.hasCheckInData==1">
                            <div style="background-color: #71e155; text-align: center; color: #ffffff; font-weight: bolder; border-radius: 5px;">
                                已跟进
                            </div>
                        </div>
                        <div v-else-if="scope.row.hasCheckInData==0">
                            <div style="background-color: #fc9e61; text-align: center; color: #ffffff; font-weight: bolder; border-radius: 5px;">
                                未跟进
                            </div>
                        </div>
                        <div v-else-if="scope.row.hasCheckInData==2">
                            <div style="background-color: #e15757; text-align: center; color: #ffffff; font-weight: bolder; border-radius: 5px;">
                                登记超时
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="下单时间" prop="orderStartTime" width="100"></el-table-column>
                <el-table-column label="预计最晚送达时间" prop="orderPreEndDeliveryTime" width="150"></el-table-column>
                <el-table-column label="商品名称" width="700">
                    <template #default="scope">
                        {{ scope.row.torderProductList[0].skuName }}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="所在城市" prop="buyerCityName" width="90"></el-table-column> -->
                <!-- <el-table-column label="所在城区" prop="buyerCountryName" width="90"></el-table-column> -->
                <el-table-column label="客户名" prop="buyerFullName" width="90"></el-table-column>
                <el-table-column label="详细地址" prop="buyerFullAddress" width="380"></el-table-column>
                <el-table-column label="取消时间" prop="orderCancelTime" width="150"></el-table-column>
                <el-table-column label="订单取消备注" prop="orderCancelRemark" width="400"></el-table-column>
                <el-table-column label="毛利" prop="profitMoneyView" width="90" fixed="right"></el-table-column>
                <el-table-column label="负责客服" prop="realname" width="90" fixed="right"></el-table-column>
                <el-table-column label="操作" width="100" fixed="right">
                    <template #default="{ row }">
                        <div v-if="row.hasCheckInData==0">
                            <el-link :underline="false" @click="information(row)">
                                登记订单
                            </el-link>
                        </div>
                        <div v-if="row.hasCheckInData==1">
                            <el-link :underline="false" @click="information(row)">
                                修改订单
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
                <template #empty>
                    <el-empty description="没有数据" />
                </template>
            </el-table> 
            <el-pagination
                v-model:current-page="orderQueryDTO.pageNo"
                v-model:page-size="orderQueryDTO.pageSize"
                layout="total, prev, pager, next, sizes"
                :page-sizes="[20, 40, 60]"
                :page-size="20"
                background
                :total="total"
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                style="justify-content: flex-end; 
                position: fixed; 
                bottom: 60px; 
                width: 80%;"
            />
        <orderInformation ref="infotableShow" :orderIdData="orderIdData"></orderInformation>
        <orderProfitMoney ref="profitShow" :queryOrderData="queryOrderData"></orderProfitMoney>
    </el-card>
</template>

<script setup>
import { ref } from 'vue'
import {
    selectOrderInfoPage,
    selectOrderStatus
} from '@/api/order.js';
import orderInformation from './orderInformation.vue'
import orderProfitMoney from './orderProfitMoney.vue'
import axios from 'axios';
import { useTokenStore } from "@/store/token";

const loading = ref(true)
const fullscreenLoading = ref(false)

const orderQueryDTO=ref({
    orderId: '',
    shopName: '',
    orderStatus: '',
    checkStatus: '0',
    startDeliveryTime:'',
    endDeliveryTime: '',
    userId: '',
    pageNo: 1,
    pageSize: 10
})


const total = ref(null)//总条数

const orderInfo=ref([
    {
        orderId: '',
        shopName: '',
        orderStatusName: '',
        orderStartTime: '',
        orderCancelTime: '',
        orderCancelRemark: '',
        buyerCityName: '',
        buyerCountryName: '',
        buyerFullName: '',
        buyerFullAddress: '',
        profitMoneyView:''
    }
])

const orderStatus=ref([
    {
      id: '',
      orderStatus: '',
      orderStatusName: '',
      delFlag: '',
      createTime: '',
      updateTime: ''
    }
])

const infotableShow=ref();
const profitShow=ref();
const orderIdData=ref({
    orderId:''
})
const queryOrderData = ref({
  startDeliveryTime: '',
  endDeliveryTime: '',
  shopName: ''
})

const init = ()=>{
    // orderIdData.value=props.userInfo
}
init()

const orderList = async()=>{
    loading.value=true
    var dateStringList = orderQueryDTO.value.orderPreEndDeliveryTime;
    if(dateStringList!=null && dateStringList!=''){
        var startTime=dateStringList[0]
        var endTime=dateStringList[1]
        var startTimeFormat=formatDate(startTime);
        var endTimeFormat=formatDate(endTime);
        orderQueryDTO.value.startDeliveryTime=startTimeFormat
        orderQueryDTO.value.endDeliveryTime=endTimeFormat
    }


    const orderId=orderQueryDTO.value.orderId.split(/\s+/).join('');
    const shopName=orderQueryDTO.value.shopName.split(/\s+/).join('');
    orderQueryDTO.value.orderId=orderId
    orderQueryDTO.value.shopName=shopName
    let result=await selectOrderInfoPage(orderQueryDTO.value);
    orderInfo.value=result.data.records;
    total.value=result.data.total;
    loading.value=false
}
orderList();

const orderStatusList = async()=>{
    let result = await selectOrderStatus();
    orderStatus.value=result.data;
}
orderStatusList()

const information = (row)=>{
    orderIdData.value=row
    infotableShow.value.open()
}

const profitListShow = (queryData)=>{
    queryOrderData.value = queryData
    profitShow.value.open()
}

const reset = async() => {
    orderQueryDTO.value.orderId='';
    orderQueryDTO.value.shopName='';
    orderQueryDTO.value.orderStatus='';
    orderQueryDTO.value.checkStatus='0';
    orderQueryDTO.value.orderPreEndDeliveryTime='';
    orderList();
}

const downloadExcel = async()=>{
    fullscreenLoading.value = true
    const tokenStore=useTokenStore();
    try {
        // 假设这是你的文件URL
        var dateStringList = orderQueryDTO.value.orderPreEndDeliveryTime;
        console.log(dateStringList)
        if(dateStringList!=null && dateStringList!=''){
            var startTime=dateStringList[0]
            var endTime=dateStringList[1]
            var startTimeFormat=formatDate(startTime);
            var endTimeFormat=formatDate(endTime);
            orderQueryDTO.value.startDeliveryTime=startTimeFormat
            orderQueryDTO.value.endDeliveryTime=endTimeFormat
        }
        let baseUrl="/api/order/exportOrderCheckInData?userId="+orderQueryDTO.value.userId
        if(orderQueryDTO.value.startDeliveryTime!=''&&orderQueryDTO.value.endDeliveryTime!=''){
            baseUrl = baseUrl+'&startTime='+orderQueryDTO.value.startDeliveryTime+'&endTime='+orderQueryDTO.value.endDeliveryTime;   
        }
        if(orderQueryDTO.value.shopName !== ''){
          baseUrl = baseUrl + '&shopName=' + orderQueryDTO.value.shopName;
        }
        // 使用axios发送GET请求
        const response = await axios({
          method: 'get',
          url: baseUrl,
          headers:{
            'Authorization':tokenStore.getToken()
          },
          responseType: 'blob', // 重要：指示axios返回Blob对象
        });

        fullscreenLoading.value = false
        // 创建一个Blob对象
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // 创建一个指向Blob对象的URL
        const url = window.URL.createObjectURL(blob);

        // 创建一个a标签用于下载
        const link = document.createElement('a');
        link.href = url;
        // 设置文件名（可选，如果服务器没有提供）
        link.setAttribute('download', '统计数据.xlsx');

        // 触发点击事件
        document.body.appendChild(link);
        link.click();

        // 清理（可选）
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        fullscreenLoading.value = false
        console.error('下载文件时出错:', error);
      }
}

//时间格式转换
const formatDate = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();

    // JavaScript 月份是从 0 开始的，所以需要加 1
    var month = ("0" + (date.getMonth() + 1)).slice(-2);

    // 获取日期，如果小于 10 则前面补 0
    var day = ("0" + date.getDate()).slice(-2);

    // 获取小时（24小时制），如果小于 10 则前面补 0
    var hours = ("0" + date.getHours()).slice(-2);

    // 获取分钟，如果小于 10 则前面补 0
    var minutes = ("0" + date.getMinutes()).slice(-2);

    // 获取秒数，如果小于 10 则前面补 0
    var seconds = ("0" + date.getSeconds()).slice(-2);

    // 拼接成目标格式
    var string=year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    return string
}

//分页条数据模型

//当每页条数发生了变化，调用此函数
const onSizeChange = (size) => {
    orderQueryDTO.value.pageSize = size
    orderList();
}
//当前页码发生变化，调用此函数
const onCurrentChange = (num) => {
    orderQueryDTO.value.pageNo = num
    orderList();
}

</script>

<style lang="scss" scoped>
.page-container {
    min-height: 100%;
    box-sizing: border-box;

    .header {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .el-form-item{
        float: left;
        display: flex;
        flex-direction: row;
    }

    .inputBar{
        width: 120px;
    }
    
    .button{
        float: right;
        margin: 5px;
        display: flex;
        flex-direction: row-reverse; 
    }
}

#search {
    width:  100%;
    height: 100px
}
.el-link {
    margin-right: 10px;
}

</style>