<template>
    <el-card class="page-container">
        <template #header>
            <div class="header">
                <span>日志列表</span>
            </div>
        </template>
        <!-- 搜索表单 -->
        <el-form inline id="topForm">
            <div id="search" :model="logQueryDTO">
              <el-form-item label="日志类型：">
                <el-select v-model="logQueryDTO.type" placeholder="请选择" style="width: 180px;" @blur="orderList()" clearable>
<!--                  <el-option label="添加" value="1"/>
                  <el-option label="查询" value="2"/>
                  <el-option label="更新" value="3"/>
                  <el-option label="删除" value="4"/>-->
                  <el-option label="后台登录" value="5"/>
                  <el-option label="插件登录" value="6"/>
                </el-select>
              </el-form-item>
                <el-form-item label="登录时间：">
                    <el-date-picker
                        v-model="logQueryDTO.queryDateTime"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @blur="logList()">
                    </el-date-picker>
                </el-form-item>
            </div>
            <div>
                <el-button type="primary" class="button" @click="logList()">搜索</el-button>
                <el-button class="button" @click="reset()">重置</el-button>
            </div>
        </el-form>
            <el-table :data="logInfo" border style="width: 100%; height: 420px;" stripe
                    :header-cell-style="{backgroundColor: 'rgb(239 239 239)',height: '10%'}"
                    :cell-style="{fontSize: 'xx-small'}"
                    v-loading="loading"
                >
                <el-table-column label="日志类型">
                  <template #default="scope">
                    <div v-if="scope.row.type === 1">添加</div>
                    <div v-else-if="scope.row.type === 2">查询</div>
                    <div v-else-if="scope.row.type === 3">更新</div>
                    <div v-else-if="scope.row.type === 4">删除</div>
                    <div v-else-if="scope.row.type === 5">后台登录</div>
                    <div v-else-if="scope.row.type === 6">插件登录</div>
                  </template>
                </el-table-column>
                <el-table-column label="日志属性" prop="attribute" width="200" />
                <el-table-column label="日志详情" prop="detail" />
                <el-table-column label="登录时间" prop="createTime" />
                <template #empty>
                    <el-empty description="没有数据" />
                </template>
            </el-table> 
            <el-pagination
                v-model:current-page="logQueryDTO.pageNo"
                v-model:page-size="logQueryDTO.pageSize"
                layout="total, prev, pager, next, sizes"
                :page-sizes="[20, 40, 60]"
                :page-size="20"
                background
                :total="total"
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                style="justify-content: flex-end; 
                position: fixed; 
                bottom: 60px; 
                width: 80%;"
            />
    </el-card>
</template>

<script setup>
import { ref } from 'vue'
import { selectLogPage } from '@/api/log.js';
const loading = ref(true)

const logQueryDTO = ref({
    type: '',
    startTime:'',
    endTime: '',
    pageNo: 1,
    pageSize: 10
})

const total = ref(null)//总条数

const logInfo = ref([
  {
      id: '',
      type: '',
      attribute: '',
      detail: '',
      create_time: '',
  }
])

const init = ()=>{
    // orderIdData.value=props.userInfo
}
init()

const logList = async()=>{
    loading.value = true
    let dateStringList = logQueryDTO.value.queryDateTime;
    if(dateStringList != null && dateStringList !== ''){
        let startTime = dateStringList[0]
        let endTime = dateStringList[1]
        let startTimeFormat = formatDate(startTime);
        let endTimeFormat = formatDate(endTime);
        logQueryDTO.value.startTime = startTimeFormat;
        logQueryDTO.value.endTime = endTimeFormat;
    }
    let result = await selectLogPage(logQueryDTO.value);
    logInfo.value = result.data.records;
    total.value = result.data.total;
    loading.value = false
}
logList();

const reset = async() => {
  logQueryDTO.value.type = '';
  logQueryDTO.value.queryDateTime = '';
  logQueryDTO.value.startTime = '';
  logQueryDTO.value.endTime = '';
  logList();
}

//时间格式转换
const formatDate = (dateString) => {
  let date = new Date(dateString);
  let year = date.getFullYear();

  // JavaScript 月份是从 0 开始的，所以需要加 1
  let month = ("0" + (date.getMonth() + 1)).slice(-2);

  // 获取日期，如果小于 10 则前面补 0
  let day = ("0" + date.getDate()).slice(-2);
  return year + "-" + month + "-" + day;
}

//分页条数据模型

//当每页条数发生了变化，调用此函数
const onSizeChange = (size) => {
  logQueryDTO.value.pageSize = size
  logList();
}
//当前页码发生变化，调用此函数
const onCurrentChange = (num) => {
  logQueryDTO.value.pageNo = num
  logList();
}

</script>

<style lang="scss" scoped>
.page-container {
    min-height: 100%;
    box-sizing: border-box;

    .header {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .el-form-item{
        float: left;
        display: flex;
        flex-direction: row;
    }

    .inputBar{
        width: 120px;
    }
    
    .button{
        float: right;
        margin: 5px;
        display: flex;
        flex-direction: row-reverse; 
    }
}

#search {
    width:  100%;
    height: 100px
}
.el-link {
    margin-right: 10px;
}

</style>