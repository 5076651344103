import request from '@/utils/request.js'

export const verifyUserService=(username)=>{
    return request.get('/api/user/verifyUsername')
}

export const userRegisterService=(registerData)=>{

    // const params=new URLSearchParams()
    // for (let key in registerData) {
    //     params.append(key,registerData[key]);
    // }

    return request.post('/api/user/register',registerData);
}

export const userLoginService=(registerData)=>{
    return request.post('/api/user/login',registerData);
}

export const verify=()=>{
    const tokenStore=useTokenStore();
    return request.get('/api/user/verifyToken');
}

export const logoutService=()=>{
    return request.get('/api/user/logout');
}

export const wechatLogin=(code)=>{
    return request.get('/plugin/user/login?code='+code)
}