<template>
    <div id="wechat">
    </div>
</template>
<script setup>
import { ref,onMounted } from 'vue'
import * as ww from '@wecom/jssdk'
import { wechatLogin } from '@/api/user.js'
const inputValue = ref('')  
onMounted(() => {
  const wwLogin = ww.createWWLoginPanel({
    el: '#wechat', // 修改为模板中的ID
    params: {
      login_type: 'CorpApp',
      appid: 'ww8b29f05221264d4f',
      agentid: '1000012',
      redirect_uri: 'https://www.xiaolatech.cn',
      state: 'loginState',
      redirect_type: 'callback',
    },
    onCheckWeComLogin({ isWeComLogin }) {
      console.log(isWeComLogin)
    },
    onLoginSuccess({ code }) {
      login(code)
      console.log(code)
    },
    onLoginFail(err) {
      console.log(err)
    },
  })
  const div = document.getElementById("wechat")
  // div.append(wwLogin.el)
})

const list = ref({
    "name":'',
    "areaIds":[]
  })

const login =async(code)=>{
  const result=await wechatLogin(code);
  list.value=result.data;
  console.log(list.value)

  let dataToSend=null;
  if(result.code=="200"){
    dataToSend = {
      message: "iframe 方法已执行",
      timestamp: Date.now(),
      additionalInfo: {
        name: list.value.name,
        areaIds: JSON.parse(JSON.stringify(list.value.areaIds))
      }
    };
    window.parent.postMessage(dataToSend, "*");
  }else{
    alert("用户核对不正确！请联系管理员")
  }
}
</script>