import request from '@/utils/request.js'

export const selectLogPage=(logQueryDTO)=>{
    let url='/api/log/selectLogPage?';
    url = url + "pageNo=" + logQueryDTO.pageNo + "&pageSize=" + logQueryDTO.pageSize;
    if(logQueryDTO.type !== "" && logQueryDTO.type !== undefined){
        url = url + "&type=" + logQueryDTO.type
    }
    if(logQueryDTO.startTime !== "" && logQueryDTO.startTime !== undefined){
        url = url + "&startTime=" + logQueryDTO.startTime
    }
    if(logQueryDTO.endTime !== "" && logQueryDTO.endTime !== undefined){
        url = url + "&endTime=" + logQueryDTO.endTime
    }
    return request.get(url)
}